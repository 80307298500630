import React from 'react'
import Card from '../common/cardProduct1'
import MK8 from '../../assets/image/hero.png'
import MK9 from '../../assets/image/mesin.png'
import HLP from '../../assets/image/hlp.png'
import product from "./product";


const product1 = () => {
    return (
        <div className='mt-12 flex flex-col lg:flex-row xs:gap-5 items-center justify-between'>
            <Card image={MK8} title='Mesin Rokok MK8' description='Kami menyediakan mesin rokok MK8 berkualitas tinggi dalam berbagai ukuran sesuai pesanan Anda. Mesin ini sangat cocok untuk kecepatan mesin rokok yang sedang berjalan di pabrik Anda.' link='/detail-mk8' />
            <Card image={MK9} title='Mesin Rokok MK9' description='Kami menyediakan mesin rokok MK9 berkualitas tinggi dalam berbagai ukuran sesuai pesanan Anda. Mesin ini sangat cocok untuk kecepatan mesin rokok yang sedang berjalan di pabrik Anda.' link='/detail-mk9' />
            <Card image={HLP} title='Mesin Rokok HLP' description='Kami menyediakan mesin rokok HLP berkualitas tinggi dalam berbagai ukuran sesuai pesanan Anda. Mesin ini sangat cocok untuk kecepatan mesin rokok yang sedang berjalan di pabrik Anda.' link='/detail-hlp' />
        </div>
    )
}

export default product1