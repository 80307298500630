import HLP from '../../assets/image/hlp.png'
import CustomButton from '../../components/common/customButton'

const hlp = () => {
    return (
        <div>
            <div className="bg-[#314747] w-screen py-10 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:max-md:flex-col-reverse xs:max-md:text-center m-auto flex justify-between items-center">
                <div className='w-5/12 xs:max-md:w-4/5 text-[#FFD700]'>
                    <h1 className='mb-2'>Mesin Rokok HLP</h1>
                </div>
                <img src={HLP} className='md:max-xl:w-1/3 xs:max-md:w-4/5 w-1/2' alt="masin rokok" />
            </div>
            <div className='w-screen 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:px-10 m-auto pt-36'>
                <p>Kami menyediakan mesin rokok HLP, yang merupakan puncak inovasi dalam industri produksi rokok. Mesin ini dirancang untuk memaksimalkan produktivitas tanpa mengorbankan kualitas, menjadikannya pilihan ideal bagi produsen rokok yang menginginkan hasil terbaik. Dengan mesin rokok HLP, Anda akan merasakan kemudahan operasional dan keunggulan performa, memastikan setiap batang rokok yang diproduksi memenuhi standar kualitas tertinggi.</p>
                <ul className='my-12 list-disc'>
                    <h1>Spesifik dalam mesin ini adalah</h1>
                    <li>Kapasitas Produksi: Bervariasi (spesifik tidak tersedia, tetapi umumnya efisiensi tinggi))</li>
                    <li>Jenis Rokok: Cocok untuk berbagai jenis rokok</li>
                    <li>Dimensi: Variabel tergantung pada model spesifik</li>
                    <li>Berat: Bervariasi berdasarkan konfigurasi dan add-on</li>
                    <li>Konsumsi Daya: Standar untuk mesin berkapasitas tinggi</li>
                    <li>Tegangan: Dapat disesuaikan berdasarkan kebutuhan klien</li>
                    <li>Operasi: Sepenuhnya otomatis, dapat disesuaikan dengan berbagai konfigurasi untuk kebutuhan produksi yang berbeda</li>
                </ul>
                <p>Untuk informasi lebih lanjut atau untuk mengatur demonstrasi produk, jangan ragu untuk menghubungi kami. Kami siap membantu Anda meningkatkan produktivitas dan kualitas produksi rokok Anda dengan Mesin Rokok HLP.</p>
                <ul className='mt-12 list-disc'>
                    <h1>Hubungi Kami:</h1>
                    <li>Email: affantsani@gmail.com</li>
                    <li>Telepon: +62 8772 5802 735</li>
                    <li>Alamat: Jalan M.H. Basuno Nomor 51, Ploso, Kudus</li>
                </ul>
                <CustomButton/>
            </div>
        </div>
    )
}

export default hlp