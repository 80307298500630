import MK9 from '../../assets/image/mesin.png'
import CustomButton from '../../components/common/customButton'

const mk9 = () => {
  return (
    <div>
      <div className="bg-[#314747] w-screen py-10 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:max-md:flex-col-reverse xs:max-md:text-center m-auto flex justify-between items-center">
         <div className='w-5/12 xs:max-md:w-4/5 text-[#FFD700]'>
            <h1 className='mb-2'>Mesin Rokok MK9</h1>
         </div>
         <img src={MK9} className='md:max-xl:w-1/3 xs:max-md:w-4/5 w-1/2' alt="masin rokok" />
      </div>
      <div className='w-screen 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:px-10 m-auto pt-36'>
         <p>Kami menyediakan mesin rokok MK 9, yang dikenal dengan kehandalannya dalam menghasilkan rokok berkualitas tinggi secara konsisten. Dirancang untuk memenuhi kebutuhan produksi skala besar, mesin ini menawarkan kinerja yang superior dan stabilitas operasional yang tak tertandingi. Pilihlah mesin rokok MK 9 untuk memastikan lini produksi Anda berjalan dengan efisien dan optimal, memenuhi permintaan pasar yang terus meningkat.</p>
         <ul className='my-12 list-disc'>
            <h1>Spesifik dalam mesin ini adalah</h1>
               <li>Kapasitas Produksi: Hingga 5000 batang rokok per menit</li>
               <li>Jenis Rokok: Rokok dengan filter.</li>
               <li>Mirip dengan MK8 tetapi bervariasi sedikit berdasarkan variasi model</li>
               <li>Berat: Sekitar 10 ton</li>
               <li>Konsumsi Daya: 10 kW</li>
               <li>Tegangan: 220V/380V</li>
               <li>Operasi: Sepenuhnya otomatis dengan sistem kontrol canggih</li>
         </ul>
         <p>Untuk informasi lebih lanjut atau untuk mengatur demonstrasi produk, jangan ragu untuk menghubungi kami. Kami siap membantu Anda meningkatkan produktivitas dan kualitas produksi rokok Anda dengan Mesin Rokok MK 9.</p>
          <ul className='mt-12 list-disc'>
              <h1>Hubungi Kami:</h1>
              <li>Email: affantsani@gmail.com</li>
              <li>Telepon: +62 8772 5802 735</li>
              <li>Alamat: Jalan M.H. Basuno Nomor 51, Ploso, Kudus</li>
          </ul>
          <CustomButton/>
      </div>
    </div>
  )
}

export default mk9