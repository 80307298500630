const material = [
   {name: 'PISAU GEAR/ Cigaratte'},
   {name: 'PISAU FILTER'},
   {name: 'PISAU TIP'},
   {name: 'PISAU INCREATUR'},
   {name: 'GREDA ASAH PISAU CIGAR ATAS'},
   {name: 'GREDA ASAH PISAU CIGAR BAWAH'},
   {name: 'KARBON'},
   {name: 'KARET PELONTAR'},
   {name: 'GARNITURE (Sedia Semua ukuran untuk mesin MK8, MK 9, Super 9, Protos)'},
   {name: 'GARNITURE (Sedia Semua ukuran untuk mesin MK8, MK 9, Super 9, Protos)'},
   {name: 'GARNITURE (Sedia Semua ukuran untuk mesin MK8, MK 9, Super 9, Protos) '},
   {name: 'TERFLON TAPE 3M'},
   {name: 'TEFLON GLUE U '},
   {name: 'SISIR BESI HOPER'},
   {name: 'TALI HOPER'},
   {name: 'KOLEKTOR TUBE'},
   {name: 'ELEVATOR HOPER '},
   {name: 'DURI ELEVATOR DURI'},
   {name: 'DURI ELEVATOR GERGAJI'},
   {name: 'DURI DRAM HOPER KASAR'},
   {name: 'DURI DRAM HOPER HALUS'},
   {name: 'BATU ASAH PISAU FILTER'},
   {name: 'TENSION ROLL TIPING'},
   {name: 'TENSION ROLL TINTA KECIL'},
   {name: 'TENSION ROLL TINTA BESAR '},
   {name: 'TOMPIST SLIM'},
   {name: 'TOMPIST REGULER'},
   {name: 'WENOWER HOPER'},
   {name: 'KODRAM SLIM TP 60'},
   {name: 'KODRAM REGULER TP 60'},
   {name: 'KODRAM REGULER TP 50'},
   {name: 'AS KODRAM REGULER TP 50'},
   {name: 'HOLDER PISAU TIPING 70'},
   {name: 'SIKAT PISAU TP'},
   {name: 'PVC TP GAET'},
   {name: 'WAS PLATE TRANSFER DRAM'},
   {name: 'HAND ROLL SLIM'},
   {name: 'HAND ROLL REGULER'},
   {name: 'DUDUKAN HAND ROLL'},
   {name: 'SEGMEN SLIM TRANSFER DRAM '},
   {name: 'SEGMEN SLIM TRANSFER DRAM REGULER'},
   {name: 'SEGMEN ROLLING DRAM'},
   {name: 'PISAU KATOB'},
   {name: 'LEZER KATOB (VIBEL)'},
   {name: 'LUV LEZER DIMETER DALAM REGULER 8,6 MM'},
   {name: 'LUV LEZER DIMETER DALAM SLIM 7,5 MM'},
   {name: 'BRAKET LEZER LUV'},
   {name: 'HOLDER GRENDAKATOB ATAS'},
   {name: 'VW SLIM'},
   {name: 'VW REGULER'},
   {name: 'RUMAH HITER KUNINGAN SLIM'},
   {name: 'RUMAH HITER KUNINGAN REGULER'},
   {name: 'SOFT FOLDER SLIM'},
   {name: 'SOFT FOLDER REGULER'},
   {name: 'LONG FOLDER SLIM'},
   {name: 'LONG FOLDER REGULER'},
   {name: 'SHOE MK-9 SLIM'},
   {name: 'SHOE MK-9 REGULER'},
   {name: 'POWER SHOE'},
   {name: 'GAET BLOCK (LUAR DALAM)'},
   {name: 'DISK GAET'},
   {name: 'TRAP REL'},
   {name: 'PINKER PIN'},
   {name: 'DRAM FILTER KUNINGAN'},
   {name: 'SIR FLEIP'},
   {name: 'DUDUKAN SIR FLEIP'},
   {name: 'COVER SMALL DRUM DEPAN'},
   {name: 'COVER PICKER PIN'}
];

export default material;