import MK8 from '../../assets/image/hero.png'
import CustomButton from '../../components/common/customButton'

const mk9 = () => {
  return (
    <div>
      <div className="bg-[#314747] w-screen py-10 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:max-md:flex-col-reverse xs:max-md:text-center m-auto flex justify-between items-center">
         <div className='w-5/12 xs:max-md:w-4/5 text-[#FFD700]'>
            <h1 className='mb-2'>Mesin Rokok MK8</h1>
         </div>
         <img src={MK8} className='md:max-xl:w-1/3 xs:max-md:w-4/5 w-1/2' alt="masin rokok" />
      </div>
      <div className='w-screen 2xl:px-56 xl:px-44 lg:px-32 md:px-20 xs:px-10 m-auto pt-36'>
         <p>Kami menyediakan mesin rokok MK 8, solusi ideal bagi Anda yang mengutamakan efisiensi dan kualitas dalam produksi rokok. Dengan teknologi canggih dan desain yang ergonomis, mesin ini memastikan proses produksi yang lancar dan output yang konsisten. Mesin rokok MK 8 adalah pilihan tepat untuk meningkatkan kapasitas produksi Anda sambil menjaga kualitas terbaik pada setiap batang rokok yang dihasilkan.</p>
         <ul className='my-12 list-disc'>
            <h1>Spesifik dalam mesin ini adalah</h1>
               <li>Kapasitas Produksi: Hingga 1500 batang rokok per menit</li>
               <li>Jenis Rokok: Rokok dengan filter</li>
               <li>Sekitar 3250 mm (P) x 1200 mm (L) x 1800 mm (T)</li>
               <li>Sekitar 8 ton</li>
               <li>Konsumsi Daya: 7 kW</li>
               <li>Tegangan: 220V/380V</li>
               <li>Operasi: Sepenuhnya otomatis, digerakkan oleh sistem listrik</li>
         </ul>
         <p>Untuk informasi lebih lanjut atau untuk mengatur demonstrasi produk, jangan ragu untuk menghubungi kami. Kami siap membantu Anda meningkatkan produktivitas dan kualitas produksi rokok Anda dengan Mesin Rokok MK 8.</p>
          <ul className='mt-12 list-disc'>
              <h1>Hubungi Kami:</h1>
              <li>Email: affantsani@gmail.com</li>
              <li>Telepon: +62 8772 5802 735</li>
              <li>Alamat: Jalan M.H. Basuno Nomor 51, Ploso, Kudus</li>
          </ul>
          <CustomButton/>
      </div>
    </div>
  )
}

export default mk9